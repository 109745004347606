import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "oliCloud® - Acceso a profesionales"
    }
  },
  {
    path: '/inicio',
    name: 'Inicio',
    meta: {
      title: "Ficha de cliente - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Inicio" */ '../views/Inicio.vue')
  },
  {
    path: '/personal',
    name: 'Personal',
    meta: {
      title: "Ficha de personal - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Productores" */ '../views/Personal.vue')
  },
  {
    path: '/producto',
    name: 'Producto',
    meta: {
      title: "Entradas de producto - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Producto" */ '../views/Producto.vue')
  },
  {
    path: '/liquidaciones',
    name: 'Liquidaciones',
    meta: {
      title: "Liquidaciones y anticipos - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Liquidaciones" */ '../views/Liquidaciones.vue')
  },
  {
    path: '/solicitudes',
    name: 'Solicitudes',
    meta: {
      title: "Solicitudes - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Solicitudes" */ '../views/Solicitudes.vue')
  },
  {
    path: '/rendimientos',
    name: 'Análisis rendimientos',
    meta: {
      title: "Análisis rendimientos - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Rendimientos" */ '../views/Rendimientos.vue')
  },
  {
    path: '/documentacion',
    name: 'Documentacion',
    meta: {
      title: "Documentación - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Documentacion" */ '../views/Documentacion.vue')
  },
  {
    path: '/noticias',
    name: 'Noticias',
    meta: {
      title: "Noticias y avisos - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Noticias" */ '../views/Noticias.vue')
  },
  {
    path: '/precios',
    name: 'Precios',
    meta: {
      title: "Alertas de precios - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Noticias" */ '../views/Precios.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if(to.hash.indexOf("#tk")>-1){
    router.push({name: 'Login', params: { tk: to.hash.substring(3)}});
  }else{
    if(store.state.auth == "" && to.name != "Login"){
      location.href = "https://olicloud.es/acceso";
    }else{
      next();
    }
  }  
})

export default router
