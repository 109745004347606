<template>
  <v-navigation-drawer app dark class="primary elevation-12"
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
  >
    <v-list-item class="primary_dark px-2" style="margin: 0px; padding: 4px">
      <v-list-item-avatar>
        <v-icon>mdi-account-circle</v-icon>
      </v-list-item-avatar>

      <v-list-item-title>{{ $store.state.user }}</v-list-item-title>

      <v-btn
        icon
        @click.stop="mini = !mini"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense class="primary">
      <v-list-item router :to="item.to"
        v-for="item in items"
        :key="item.title"
        link
      >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="mini" style="position: absolute; bottom: 0px; width: 100%; display:flex; justify-content: center; margin: 0">
        <v-btn
          href="https://olicloud.es/"
          target="blank"
          text
        >  
          <v-img
            alt="OliCloud Logo"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="20"
          />
        </v-btn>
      </v-list-item>
      <v-list-item v-if="!mini" style="position: absolute; bottom: 0px; width: 100%; display:flex; justify-content: center; margin: 0">
        <v-btn
          href="https://olicloud.es/"
          target="blank"
          text
        >
          <span class="mr-2">olicloud.es</span>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
  export default {
    data () {
      return {
        drawer: false,
        items: [
          { title: 'Ficha de cliente', icon: 'mdi-account', to: 'inicio'},
          { title: 'Ficha de personal', icon: 'mdi-account-group', to: 'personal'},
          { title: 'Entradas de producto', icon: 'mdi-truck-delivery', to: 'producto'},
          { title: 'Liquidaciones y anticipos', icon: 'mdi-table-account', to: 'liquidaciones'},
          { title: 'Solicitudes', icon: 'mdi-message-text', to: 'solicitudes'},
          { title: 'Análisis rendimientos', icon: 'mdi-sack-percent', to: 'rendimientos'},
          { title: 'Alertas de precios', icon: 'mdi-alert', to: 'precios'},
          { title: 'Documentación', icon: 'mdi-file-document-multiple', to: 'documentacion'},
          { title: 'Noticias y avisos', icon: 'mdi-bell', to: 'noticias'},
        ],
        mini: true,
      }
    },
  }
</script>
<style scoped>
  .v-list-item{
    margin-left: 5px;
  }
  .v-list-item--active{
    background: #4e6779 !important;
    /* border-radius: 5px 0px 0px 5px; */
    border-color: #91dc5a;
    border-left-style: solid;
    border-left-width: 3px;
  }
  .v-list-item--link:before{
    border-radius: 5px 0px 0px 5px !important;
  }
  .v-list-item--active:before{
    opacity: 0 !important;
  }
</style>