<template>
  <v-app>
    <v-main>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'Login',
  data: () => ({}),
  mounted() {
    if(this.$route.params.tk){
      this.$store.commit('modifyAuth',this.$route.params.tk);
      this.$router.push({name: 'Inicio'});
    }else{
      this.$store.commit('modifyAuth','');
      location.href = "https://olicloud.es/acceso";
    }
  },
  methods: {
  }
}
</script>
