import genericReload from "../components/genericReload";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from "vuex-multi-tab-state";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: "",
    user: "",
    personal: [],
    productos: [],
    precios: [],
    fincas: [],
  },
  plugins: [
    createPersistedState({ storage: window.sessionStorage, paths: ["auth", "user"] }),
    createMultiTabState(),
  ],
  mutations: {
    modifyAuth(state, newAuth) {
      state.auth = newAuth;
    },
    modifyNameUser(state, newName) {
      state.user = newName;
    },
  },
  actions: {
    async loadData() {
      if (this.state.auth != "") {
        await this.dispatch("getFincas");
        await this.dispatch("getPersonal");
        // await this.dispatch("getPrecios");
        // await this.dispatch("getUser");
      }
    },
    async getPersonal() {
      const response = await genericReload(this.state.auth, `personal`);
      if (response.status === 200) {
        const json = await response.json();
        this.state.personal = json.body;
      }
    },
    async getProductos() {
      const response = await genericReload(this.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.state.productos = json.body;
      }
    },
    async getPrecios() {
      const response = await genericReload(
        this.state.auth,
        `fijacionPrecio`
      );
      if (response.status === 200) {
        const json = await response.json();
        this.state.precios = json.body;
      }
    },
    async getUser() {
      const response = await genericReload(this.state.auth, `almazara`);
      if (response.status === 200) {
        const json = await response.json();
        this.state.user = json.body.nombre;
      }
    },
    async getFincas() {
      const response = await genericReload(this.state.auth, `finca`);
      if (response.status === 200) {
        const json = await response.json();
        this.state.fincas = json.body;
      }
    },
  },
  getters: {
    productorById: (state) => (id) => {
      return state.productor.find((p) => p.id === id);
    },
    productsById: (state) => (id) => {
      return state.productos.find((product) => product.id === id);
    },
    productsByType: (state) => (type) => {
      return state.productos.filter((product) => product.producto === type);
    },
  },
});
